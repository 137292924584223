.input-search {
  &.ant-input-affix-wrapper {
    border: 1px solid #89D4F0;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #5dc1e6;
  }

  &.ant-input-affix-wrapper-focused {
    border-color: #5dc1e6;
    box-shadow: 0 0 0 2px rgba(106, 210, 236, 0.3)
  }

  .ant-input::placeholder {
    color: @dark-gray;
  }
}
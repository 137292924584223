.field-label {
    display: block;
    padding-bottom: 4px;
    font-weight: 500;

    &.required::after {
        display: inline-block;
        margin-left: 2px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.ant-form {
    textarea {
        color: #6930A3;
    }
}

.table-actions {
    span {
        display: flex;
    }
}

.form_body {

    .ant-form-item-has-error {
        input {
            border-color: #ff4d4f !important;
        }
    }
}

.sd-stripe-payment-form {
    width: 500px;
}
.sd-simple-form {

    .form-radio-group {
        flex-direction: column;
        align-items: flex-start;

        .ant-form-item-label>label {
            color: @secondary-color;
            font-size: 16px !important;
            font-weight: 600;
            margin-bottom: 10px;

            &::after {
                content: "";
            }
        }

        .ant-radio-wrapper {

            &.radio-value span {
                font-size: 14px;
            }

            span {
                color: @primary-color;
                font-size: 12.8px;

            }

            span.ant-radio.ant-radio-checked+span {
                display: flex;
                align-items: baseline;
                color: @secondary-color;

                div.ant-form-item-explain.ant-form-item-explain-connected {
                    margin-left: 35px;
                }
            }

            span.ant-input-prefix {
                color: @primary-color;
            }
        }

        &.ant-form-item {
            margin-bottom: 8px !important;
        }

        .activity-end-date {
            width: 235px !important;
            margin-left: 34px !important;
        }
    }

    .ant-select-selection-item {
        svg {
            margin-bottom: 5px;
        }
    }

    .ant-picker-input {

        .ant-picker-suffix {

            &>span {
                color: @primary-color;
            }
        }

        &>input {
            height: 30px;
            color: @primary-color;
            font-weight: 600;

            &::placeholder {
                color: @dark-gray;
                font-weight: 600;
            }
        }
    }


    button.ant-btn.ant-btn-default {
        box-shadow: none;
        border: 1px solid lighten(@dark-gray, 20);
    }

    .ant-typography {
        color: @secondary-color;
    }

    .ant-form-item {
        .ant-input-prefix {
            margin-right: 0px;
        }
    }

    .ant-input-textarea.ant-input-textarea-show-count {
        width: 100%;
    }
}

.sd-simple-form-csv {

    .upload-btn {

        span.anticon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        text-align: center;
        width: 260px !important;
    }

    .sd-upload-button-container {
        cursor: pointer;
        border-radius: 10px;
        margin-top: 10px;

        .ant-card-body {
            padding: 10px;

            .sd-upload-button {
                font-size: 14px;
            }
        }
    }
}

.simple-form-container {
    border-radius: 10px;
    padding: 30px;

    // max-width: 1100px;
    .avatar-uploader {
        .ant-upload {
            width: 100%;
            height: 142px;
            overflow: hidden;
        }

        .ant-upload-list-picture-card-container {
            position: absolute;
            top: 0;
            left: 0;

            margin: 0;
            width: 100%;
            height: 130px;

            .ant-upload-list-item-thumbnail img {
                object-fit: cover;
            }

            .ant-upload-list-item-actions {
                display: flex;
                align-items: center;

                >a {
                    margin-top: -4px;
                }
            }
        }
    }


    @media @max768 {
        padding: 20px;

        .ant-col {
            min-width: 142px;
        }
    }
}
body {
    margin: 0;
    font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


h1, h2 {
  font-weight: 600;
}

.main-layout {
  background: @body;
}
.d-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: @body;
  padding-bottom: .5rem;
}

@media @max426 {
  .main-layout {
    >main.ant-layout-content {
      padding: 0 20px 20px !important;
    }
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: @primary-color;
  transition: background-color 5000s ease-in-out 0s;
}
.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
        font-weight: 600;
    }
}

.ant-dropdown-menu-item-selected{
    background-color: lighten(@gray, 15%);
}

.ant-menu-title-content {
    font-weight: bold;
}

.ant-input-affix-wrapper {
    padding: 8px 11px !important;
}

.ant-input[disabled]{
    color: rgb(0 0 0 / 48%) !important;
}

.ant-picker-footer {
    display: none;
  }

.ant-btn {
    height: 40px;
    font-weight: 600;

    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

a.ant-btn {
    line-height: 37px;
}

.ant-btn-default.default {
    background: #F3F3F7;
    color: #A8A8A8;
    border-color: rgba(#A8A8A8, 0.1);

    &:hover {
        background: darken(#F3F3F7, 3%)
    }
}

.ant-btn-leftIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 8px !important;
            margin-right: 0 !important;
        }
    }
}
.ant-btn-rightIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    
    >span {
        width: auto;
        &:not(.anticon) {
            margin-left: 0 !important;
            margin-right: 8px !important;
        }
    }
    >img {
        max-width: 18px;
    }
}
.ant-btn-outlined-secondary {
    color: @secondary-color;
    border-color: @secondary-color;

    &[disabled] {
        border-color: @border;

        &:hover {
            border-color: @border;
        }
    }

    &:hover {
        opacity: .8;
        color: @secondary-color;
        border-color: @secondary-color;
    }
}



//
.ant-select-item {
    color: @primary-color !important;
    background-color: transparent !important;
    
    div.ant-select-item-option-content {
        color: @primary-color;
    }
    
}

.ant-select-item[aria-selected="true"] {
    color: @white!important;
    background-color: @primary-color !important;
    
    div.ant-select-item-option-content {
        color: @white;
    }
}



.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
}


.ant-form {


    .form-radio-button-group {
        
        .ant-radio-button-wrapper {
            height: 45px;
            padding-top: 3px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 25px;

            &.calendar-days {
                padding-left: 17px;
                padding-right: 20px;
                width: 45px;
                white-space: nowrap;
                border-radius: 0px;
                height: 45px;
                
            }

            &.selected  {
                background: @primary-color;
                color: @white;
            }

            &.ant-radio-button-wrapper-checked:focus-within{
                box-shadow: none;
                border-color: 1px solid #d9d9d9;
            }

            .ant-radio-button-checked{
                border-color: 1px solid #d9d9d9 !important;
            }

            &.ant-radio-button-wrapper-checked {
                span.ant-radio-button.ant-radio-button-checked{
                    border-color: 1px solid #d9d9d9 !important;
                }

                &:not(.ant-radio-button-wrapper-disabled){
                    border-color: #d9d9d9;
                }
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
                background-color: #d9d9d9;
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                border-color: 1px solid #d9d9d9 !important;
            }
        }
    }

    label {
        font-size: 12px !important;
        color: @secondary-color;
    }
    &-item-explain.ant-form-item-explain-error {
        padding: 10px 8px 20px 0px;
    }

    // Change placeholder color
    .ant-input::placeholder
    {
        color: @dark-gray;
        font-weight: 600;
    }

    .ant-input {
        font-weight: 600;
    }

    .ant-select-selection-placeholder {
        color: @dark-gray;
        font-size: 14px;
        font-weight: 600;
    }

    .ant-select-arrow > * {
        color: @primary-color;
    }

    .ant-select-selection-item {
        font-size: 14px;
        font-weight: 600;
    }

    .ant-input-number {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 272px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 2px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.3s;
    }


    .merchant-image-preview {
        width: 100%;
        height: 272px;
    }

    

    .ant-upload-list-picture-card-container{
        display: inline-block;
        width: 100%;
        height: 272px;
        vertical-align: top;
    }

    .sd-upload-button {
        font-size: 16px;
        font-weight: 900;
        color: @secondary-color;
    }

    .ant-input-affix-wrapper {

        input {
            color: @primary-color;
        }
    }


    &.sd-form {

        .ant-input-prefix {
            margin-right: 0px;
        }
        
    }

    .sd-pagination {

        .ant-pagination-options {
            display: none;
          }
          
    }

}

//Default Ant Button

button.ant-btn.ant-btn-default{
    border: none;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    color: @primary-color;
    font-weight: 600;
}


//Calendar
.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
    display: none;
}


.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    font-size: 18px
}

.ant-menu-inline {
    .ant-menu-item::after {
        right: auto;
        left: 0;
        border-color: @secondary-color;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: @secondary-color;
    color: #ffffff;
}

.anticon svg {
    vertical-align: middle;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 80px;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2);
}

.ant-form-item {
    margin-bottom: 18px !important;
}
.ant-form-item-with-help {
    margin-bottom: 0 !important;
}
.ant-form-item-explain{
    // min-height: 18px !important;
    font-size: 12px !important;
}

a {
    color: @secondary-color;
}

.ant-form-item-control-input-content{
    justify-content: center;
    display: flex;

    & > input {
        height: 40px;
        color: @primary-color;
    }

    .ant-select {
        color: @primary-color;
        .ant-select-selector {
            // height: 40px !important;
            align-items: center;
        }
    }
}

.ant-table-thead > tr > th {
    background: @secondary-color !important;
    font-weight: 600 !important;
}

.ant-tabs-tab-btn {
    font-weight: 600;
    color: @dark-gray;
}

.ant-layout-sider-children {
    background-color: @white;
}

@media @max768 {
    .ant-menu-inline-collapsed-tooltip {
        display: none !important;
    }
}

/***** Secret Dates Custom Classes *****/

//Antd Notification

.ant-notification {
    
    .sd-notification {
        background: @primary-color;
        
        .ant-notification-notice-message,
        .ant-notification-notice-description {
            color: @white !important;
        }
    }
}


//Badge
.sd-badge {
    .ant-badge-count {
        box-shadow: none;
        background: @primary-color;
        font-weight: 600;
    }
}


.ant-modal-confirm-body-wrapper{
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
}
.ant-modal-confirm .ant-modal-confirm-btns{
    display: flex !important;
    justify-content: center !important;
}

.modal-error-content{
    margin-top: 10px !important;
    margin-bottom: -5px !important;
}

//DATE PICKER
.react-datepicker__current-month{
    color: #121212 !important;;
}

.react-datepicker__month-read-view{
    font-family: 'Avenir';
    visibility: visible;
    display: flex;
    background-color: #ffffff;
    flex-direction: row;
    width: 95px;
    padding: 4px;
    margin-top: 4px;
    border: 1px solid #121212 !important;
    .react-datepicker__month-read-view--down-arrow{
        right: 7px;
        top: 8px;
        border-color: #6930a3;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
    }
    .react-datepicker__month-read-view--selected-month {
        margin-left: 4px;
        color: #6930a3;
        font-weight: 600;
    }
}

.react-datepicker__month-container{
    font-family: 'Avenir';
}

.react-datepicker__header{
    background-color: #ffffff !important;
    margin-top: 5px;
}
.react-datepicker__navigation--previous {
    margin-top: 10px;
}
.react-datepicker__navigation--next{
    margin-top: 10px;
}
.react-datepicker__month-dropdown{
    background-color: #ffffff !important;
    width: 66% !important;
    left: 6% !important;
    // height: 150px;
    // overflow-y: scroll;
}
.react-datepicker__month-option--selected{
    color: #6930a3;
}
// .react-datepicker__year-dropdown::after{
//     color: #6930a3 !important;
//     content: "\25BC";
//     display: block;
//     position: relative;
//     bottom: 20px;
// }
.react-datepicker__year-dropdown .react-datepicker__year-option:last-child::after {
    color: #6930a3 !important;
    content: unset !important;
    display: block;
    position: relative;
}

// .react-datepicker__navigation:last-child{
//     height: 15px !important;
// }

// .react-datepicker__navigation:first-child{
//     height: 35px !important;
// }  
.react-datepicker__year-option:last-of-type{
    a{
        height: 25px !important;
    }
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: unset;
    position: absolute;
    top: 4%;
    left: 45%;
    margin: auto;
    color: #6930a3 !important;
}
.react-datepicker__month-option{
    line-height: 25px;
}
.react-datepicker__month-option:hover{
    background-color: #6930a378 !important;
}

.react-datepicker__day:hover{
    background-color: #6930a378 !important;
}
.react-datepicker__year-option:hover{
    background-color: #6930a378 !important;
}
.react-datepicker__year-option{
    line-height: 25px !important;
}
.react-datepicker__year-dropdown--scrollable{
    background-color: #ffffff !important;
    width: 66% !important;
    left: 25% !important;
    height: 242px !important;
}
.react-datepicker__day--keyboard-selected{
    background-color: #6930a3 !important;
}

.react-datepicker__input-container{
    input{
        font-family: 'Avenir';
        height: 42px;
        border-radius: 5px;
        padding-left: 10px;
        color: #6930a3;
        font-weight: 600;
        width: 100%;
        outline: unset !important;
        border: #d9d9d9  1px solid !important;
        cursor: pointer;
    }
    input::placeholder { color: #b2a1a1; }
    input:focus-visible{
        outline: unset !important;
        border: #d9d9d9 1px solid !important;
    }
}

.ant-form-item-has-error{
    .react-datepicker__input-container{
        // outline: #ff4d4f auto 1px !important;
        border-width: thin !important;
        border-radius: 4px;
        input{
            font-family: 'Avenir';
            height: 40px;
            border-radius: 5px;
            padding-left: 10px;
            color: #6930a3;
            font-weight: 600;
            width: 100%;
            outline: unset !important;
            border: red 1px solid !important;
            cursor: pointer;
            // box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)
        }
        input::placeholder { color: #b2a1a1; }
        input:focus-visible{
            outline: unset !important;
            border: red 1px solid !important;
        }
    }
}

.react-datepicker__year-option--selected{
    color: #6930a3;
}

.empty-input{
    
    .react-datepicker__day--keyboard-selected {
        background-color: #8556b4b0 !important;
    }
}

.react-datepicker__year-read-view{
    font-family: 'Avenir';
    visibility: visible;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    width: 95px;
    padding: 4px;
    margin-top: 6px;
    margin-left: 4px;
    border: 1px solid #121212 !important;
    .react-datepicker__year-read-view--down-arrow{
        right: 7px;
        top: 8px;
        border-color: #6930a3;
        border-width: 1px 1px 0 0;
        height: 7px;
        width: 7px;
    }
    .react-datepicker__year-read-view--selected-year {
        margin-left: 4px;
        color: #6930a3;
        font-weight: 600;
    }
}

.react-datepicker__day--selected{
    background-color: #6930a3 !important;
}
.react-datepicker-icon{
    position: absolute;
    bottom: 15px;
    right: 12px;
    width: 14px;
    height: 14px;
    fill: #6930a3;
    pointer-events: none;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
    background-color: #ffffff !important;
}

.react-datepicker__navigation--years-upcoming{
    background-position: center;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAQMAAADaua+7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAaTCjcviNwQAAAAJ0Uk5TAP9bkSK1AAAAHUlEQVR4nGNgIAZIALENENcB8X8GBsb/DURpAwIAl2cDUsBMe4oAAAAASUVORK5CYII=) !important;
    background-position-x: center;
    background-position-y: 5px;
    height: 25px !important;
    top: 0 !important;
    margin-left: 50px !important;
}

.react-datepicker__navigation--years-previous{
    background-position: center;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAQMAAADaua+7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAaTCjcviNwQAAAAJ0Uk5TAP9bkSK1AAAAHElEQVR4nGNgIBIw/m9gYPgPZNQBsQ0QSxClDQCmuwNS68xvYQAAAABJRU5ErkJggg==) !important;
    height: 25px !important;
    background-position-x: center;
    background-position-y: -3px;
    top: 0 !important;
    margin-left: 50px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before{
    z-index: 0 !important
}

.ant-form-item-explain.ant-form-item-explain-error{
    padding: 3px 8px 20px 0px;
}